import React, {useEffect, useState} from "react";
import { Card, TableCell } from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import TableBody from "@mui/material/TableBody";
import { Indicator } from "./Indicator";
import { secondsAgo } from "./Header";
import { useTimeBasedRefresher } from "../TimeRefresher";
import { StatusPagePublicDetails } from "../../api/Types";
import Grid from "@mui/material/Grid";

export function useWindowSize() {
  const [size, setSize] = useState({width: window.innerWidth, height: window.innerHeight});
  useEffect(() => {
    const update = () => {
      setSize({width: window.innerWidth, height: window.innerHeight});
    };
    window.addEventListener("resize", update)
    return () => window.removeEventListener("resize", update);
  }, []);

  return size;
}

export function StatusGrid(props: { detail: StatusPagePublicDetails }) {
  useTimeBasedRefresher();
  const statuses = props.detail.statuses || [];
  const {width} = useWindowSize();

  if(width < 800) {
    return (
      <Card style={{padding: 12}}>
        {statuses.map((row, index) => (
            <div key={index.toString()}>
              <Grid container alignItems="center">
                <Grid item xs={6}>
                  <div style={{fontSize: "1.2rem",}}>{row.name}</div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                    <Indicator value={row.current} />
                  </div>
                </Grid>
                <Grid item xs={9}>
                  <div>Updated {secondsAgo(row.lastUpdated, true)}</div>
                </Grid>
                <Grid item xs={3}>
                  <div style={{display: "flex", justifyContent: "flex-end"}}>
                    {(row.uptime * 100).toFixed(3)}%
                  </div>
                </Grid>
              </Grid>
              <div style={{height: 20}} />
              History: {moment().format("ddd")}-{moment().add(-6, "day").format("ddd")}
              <Grid container spacing={1} justifyContent="space-between">
                  <Grid item>
                    <Indicator value={row.days[0]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[1]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[2]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[3]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[4]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[5]} />
                  </Grid>
                  <Grid item>
                    <Indicator value={row.days[6]} />
                  </Grid>
              </Grid>
            </div>
          ))}
      </Card>
    );
  }

  return (
    <Card>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>Now</TableCell>
            <TableCell>Uptime</TableCell>
            <TableCell>Updated</TableCell>
            <TableCell padding="checkbox">{moment().format("ddd")}</TableCell>
            <TableCell padding="checkbox">
              {moment().add(-1, "day").format("ddd")}
            </TableCell>
            <TableCell padding="checkbox">
              {moment().add(-2, "day").format("ddd")}
            </TableCell>
            <TableCell padding="checkbox">
              {moment().add(-3, "day").format("ddd")}
            </TableCell>
            <TableCell padding="checkbox">
              {moment().add(-4, "day").format("ddd")}
            </TableCell>
            <TableCell padding="checkbox">
              {moment().add(-5, "day").format("ddd")}
            </TableCell>
            <TableCell padding="checkbox">
              {moment().add(-6, "day").format("ddd")}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {statuses.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.name}</TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.current} />
              </TableCell>
              <TableCell>{(row.uptime * 100).toFixed(3)}%</TableCell>
              <TableCell>{secondsAgo(row.lastUpdated)}</TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[0]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[1]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[2]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[3]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[4]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[5]} />
              </TableCell>
              <TableCell padding="checkbox">
                <Indicator value={row.days[6]} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}
