import { useEffect, useState } from "react";
import { EventEmitter } from "events";

const events = new EventEmitter();
const eventName = "increment";
setInterval(() => events.emit(eventName), 1000 * 5);
events.setMaxListeners(200);

export function useTimeBasedRefresher() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [counter, setCounter] = useState<number>();

  useEffect(() => {
    let i = 0;
    const listener = () => setCounter(i++);
    events.addListener(eventName, listener);

    return () => {
      events.removeListener(eventName, listener);
    };
  });
}
