import { Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import { successGreen, warningYellow } from "../monitorList/colors";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import * as React from "react";
import Tooltip from "@mui/material/Tooltip";
import grey from "@mui/material/colors/grey";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    warn: {
      color: warningYellow,
      paddingTop: "4px !important",
    },
    success: {
      color: successGreen,
      paddingTop: "4px !important",
    },
    none: {
      color: grey["300"],
      paddingTop: "4px !important",
    },
  })
);

export function Indicator(props: { value: boolean | null }) {
  const styles = useStyle();

  return (
    <Tooltip
      title={
        props.value === null
          ? "No data for this time period"
          : props.value
          ? "Everything working normally"
          : "Things aren't working right"
      }
    >
      <div
        className={
          props.value === null
            ? styles.none
            : props.value
            ? styles.success
            : styles.warn
        }
      >
        {props.value === null ? (
          <FiberManualRecordIcon color="inherit" />
        ) : props.value ? (
          <CheckCircleIcon color="inherit" />
        ) : (
          <WarningIcon color="inherit" />
        )}
      </div>
    </Tooltip>
  );
}
