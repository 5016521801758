import React, { PropsWithChildren } from "react";
import { Paper } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    outerPadding: {
      padding: theme.spacing(2),
    },
    outerPaddingSlim: {
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    outerPaddingWithSlimBottom: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
    },
    zeroElevation: {
      border: "1px solid " + grey["300"],
    },
    card: {
      overflow: "hidden",
    },
  })
);

export function Card(
  props: PropsWithChildren<{
    elevation?: number;
    slimBottom?: boolean;
    slim?: boolean;
  }>
) {
  const styles = useStyles();

  return (
    <Paper
      classes={{ elevation0: styles.zeroElevation }}
      elevation={props.elevation === undefined ? 1 : props.elevation}
      className={styles.card}
    >
      <div
        className={
          props.slim
            ? styles.outerPaddingSlim
            : props.slimBottom
            ? styles.outerPaddingWithSlimBottom
            : styles.outerPadding
        }
      >
        {props.children}
      </div>
    </Paper>
  );
}
