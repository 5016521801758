import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import * as React from "react";
import { PropsWithChildren } from "react";

const styles = (theme: Theme) =>
  createStyles({
    body: {
      backgroundColor: theme.palette.grey[100],
      flex: 1,
      width: "100%",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "column",
      paddingBottom: theme.spacing(5),
    },
    center: {
      maxWidth: 1000,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
    centerNarrow: {
      maxWidth: 800,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
    },
  });

const useStyles = makeStyles(styles);

export function BodyCenter(props: PropsWithChildren<{ narrow?: boolean }>) {
  const styles = useStyles();

  return (
    <div className={props.narrow ? styles.centerNarrow : styles.center}>
      {props.children}
    </div>
  );
}

export function Body(props: PropsWithChildren<{ narrow?: boolean }>) {
  const styles = useStyles();
  return (
    <div className={styles.body}>
      <BodyCenter narrow={props.narrow}>{props.children}</BodyCenter>
    </div>
  );
}
