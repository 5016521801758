import { api } from "../api";
import { CompanyStatus } from "../api/Types";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

export const notAuthenticated = "no-auth";

interface ExtendedCompanyStatus extends CompanyStatus {
  demoMode?: boolean;
}

type Status = {
  status?: ExtendedCompanyStatus;
  isLoading: boolean;
  isAuthenticated: boolean;
  promise: Promise<any>;
  reload(): void;
};

const defaultState = {
  isLoading: true,
  isAuthenticated: false,
  promise: Promise.resolve(),
  reload: () => console.warn("not setup"),
};

export const CompanyStatusContext = createContext<Status>(defaultState);

export function CompanyStatusProvider(props: React.PropsWithChildren<any>) {
  const [status, setStatus] = useState<Status>(defaultState);
  const [isInit, setIsInit] = useState(false);

  const fetch = useCallback(async () => {
    try {
      const result = await api.ping();

      setStatus((currentStatus) =>
        Object.assign({}, currentStatus, {
          status: result,
          isLoading: false,
          isAuthenticated: true,
          promise: Promise.resolve(result),
        })
      );

      if (!result.isActive) {
        if (result.isHeroku) {
          api.logout();
          return;
        }
      }
    } catch (e: any) {
      setStatus((currentStatus) =>
        Object.assign({}, currentStatus, {
          isLoading: false,
          isAuthenticated: false,
          promise: Promise.reject(e.message),
        })
      );
      return;
    }
  }, []);

  const load = useCallback(() => {
    setStatus((currentStatus) =>
      Object.assign({}, currentStatus, {
        isLoading: true,
        isAuthenticated: false,
        promise: fetch(),
      })
    );
  }, [fetch]);

  const reload = useCallback(() => {
    setStatus((currentStatus) =>
      Object.assign({}, currentStatus, {
        promise: fetch(),
      })
    );
  }, [fetch]);

  useEffect(() => {
    setStatus((currentStatus) =>
      Object.assign({}, currentStatus, {
        reload: reload,
      })
    );
  }, [reload]);

  useEffect(() => {
    if (isInit) return;
    setIsInit(true);

    load();
    let timer = setInterval(load, 30 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [load, isInit]);

  return (
    <CompanyStatusContext.Provider value={status}>
      {props.children}
    </CompanyStatusContext.Provider>
  );
}

type CompanyStatusOutput =
  | "loading"
  | typeof notAuthenticated
  | ExtendedCompanyStatus;

export function useCompanyStatus(): CompanyStatusOutput {
  const status = useContext(CompanyStatusContext);

  if (status.isLoading) return "loading";
  if (!status.isAuthenticated) return notAuthenticated;
  if (status.status === undefined) throw new Error("status should be set");

  return status.status;
}

export function useCompanyStatus2(): ExtendedCompanyStatus | null {
  const status = useContext(CompanyStatusContext);
  return status.status || null;
}

export function companyStatusIsValid(
  input: CompanyStatusOutput
): input is ExtendedCompanyStatus {
  return input !== "loading" && input !== notAuthenticated;
}
