import * as React from "react";
import { Body } from "./components/Body";
import {Grid, Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import {green} from "@mui/material/colors";
import { Header } from "./components/statusPage/Header";
import { Messages } from "./components/statusPage/Messages";
import { StatusGrid } from "./components/statusPage/StatusGrid";
import { useAsync } from "./api/Async";
import { api } from "./api";
import { useParams, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { Footer } from "./components/statusPage/Footer";
import Alert from "@mui/lab/Alert";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 150,
      maxHeight: 50,
    },
    marginTop: {
      height: theme.spacing(4),
    },
    brandPadding: {
      padding: theme.spacing(2),
    },
    status: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: green["800"],
      color: "#fff",
    },
  })
);

export function StatusPage(props: {
    domainMode?: boolean;
}) {

  const styles = useStyle();
  const id = useRouteMatch<{id: string}>("/status/:id")?.params?.id;

  const [lastUpdated, setLastUpdated] = useState(new Date());
  const details = useAsync(
    async () => {
      const result = await api.statusPagePublicDetails(id);
      setLastUpdated(new Date());
      return result;
    },
    { withoutCompany: true }
  );

  const reload = details.reload;
  useEffect(() => {
    const interval = setInterval(() => reload(), 30 * 1000);
    return () => {
      clearInterval(interval);
    };
  }, [reload]);

  if (!details.result) {
      if((details.error || "").indexOf("Error: sql: no rows in result set") !== -1) {
          return (
              <React.Fragment>

                <Body narrow>
                    <div style={{height: 50}} />
                    <div style={{fontSize: "2rem"}}>
                        Sorry.
                    </div>
                    That status page doesn't exist or hasn't been made public.
                </Body>
                  <Footer />
              </React.Fragment>
          );
      }

    return (
      <React.Fragment>
        <Body narrow>{details.LoadingElement}</Body>
        <Footer />
      </React.Fragment>
    );
  }

  if(!details.result.isPublic && details.result.id == 0) {
      return (
          <React.Fragment>
              <Body narrow>
                  <div style={{height: 50}} />
                  <div style={{fontSize: "2rem"}}>
                      Sorry.
                  </div>
                  That status page hasn't been made public.
              </Body>
              <Footer />
          </React.Fragment>
      );
  }

  return (
    <React.Fragment>
      <Body narrow>
          <div style={{paddingLeft: 10, paddingRight: 10}}>
            {!details.result.isPublic && <>
                    <div className={styles.marginTop} />
                    <Alert color="error">This status page is set to private, only you can see this page</Alert>
                </>
            }
            <div className={styles.marginTop} />
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Header
                  lastUpdated={lastUpdated.toString()}
                  details={details.result}
                />
              </Grid>
              {}
              <Grid item>
                <Messages messages={details.result.messages || []} />
              </Grid>
              <Grid item>
                <StatusGrid detail={details.result} />
              </Grid>
            </Grid>
          </div>
      </Body>
      <Footer />
    </React.Fragment>
  );
}
