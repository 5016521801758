export const defaultMuiTheme = {
  palette: {
    primary: {
      main: "#0366d6",
      contrastText: "#fff",
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiTextField: {
      root: {
        minWidth: "250px",
      },
    },
    MuiGrid: {
      container: {
        alignItems: "stretch",
      },
    },
  },
};
