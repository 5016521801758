import React from "react";
import {LocalizationProvider} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StatusPage } from "./app-src/StatusPage";
import { defaultMuiTheme } from "./app-src/Theme";
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme(defaultMuiTheme);

class App extends React.PureComponent<{}> {
    render() {
        return (
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Router>
                            <Switch>
                                <Route path="/">
                                    <StatusPage domainMode />
                                </Route>
                            </Switch>
                        </Router>
                    </LocalizationProvider>
                </ThemeProvider>
        );
    }
}

export default App;
