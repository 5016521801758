import { ErrResponse } from "./Types";
import React, { useCallback, useEffect, useState } from "react";
import { notAuthenticated, useCompanyStatus } from "../core/auth";
import { Loading } from "../components/Loading";
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// deprecated: please use nate-api-tools/useAsync()
export function useAsync<T>(
  fx: () => Promise<T | ErrResponse>,
  options?: { withoutCompany: boolean }
): {
  loading: boolean;
  error: string | null;
  result: T | null;
  reload: () => void;
  LoadingElement: JSX.Element | null;
} {
  const [loading, setLoading] = useState(true);
  const [value, setValue] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isInit, setIsInit] = useState(false);

  const load = useCallback(async () => {
    try {
      setError(null);
      setLoading(true);
      setIsInit(true);
      const result = await fx();
      if (result && typeof result === "object" && "error" in result)
        throw new Error(result.error);
      setValue(result);
    } catch (e: any) {
      setError(e.toString());
    }

    setLoading(false);
  }, [fx]);

  const reload = useCallback(load, [fx]);
  const status = useCompanyStatus();

  useEffect(() => {
    if (isInit) return;
    if (!options?.withoutCompany) {
      if (status === "loading") return;
      if (status === notAuthenticated) {
        setError("Not authenticated");
        return;
      }
    }

    load();
  }, [isInit, status, load, options]);

  const LoadingElement = LoadingEl(loading, error, reload);

  return {
    LoadingElement,
    loading,
    error,
    result: value,
    reload,
  };
}

function LoadingEl(
  loading: boolean,
  error: string | null,
  reload?: () => void
) {
  if (loading) {
    return <Loading />;
  }

  if (error) {
    return (
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography color="error">{error}</Typography>
        </Grid>
        {reload && (
          <Grid item>
            <Button onClick={() => reload()}>Retry</Button>
          </Grid>
        )}
      </Grid>
    );
  }

  return null;
}

export interface AsyncAction<T, U> {
  loading: boolean;
  error: string | null;
  callback(input: U): Promise<void>;
  result: T | null;
  LoadingElement: JSX.Element | null;
}

export function useAsyncAction<T, U = any>(
  callback: (arg: U) => Promise<T>,
  dependsOn: any[]
): AsyncAction<T, U> {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [result, setResult] = useState<T | null>(null);
  const depends = [...dependsOn, callback];

  const theCallback = useCallback(async (arg) => {
    try {
      setError(null);
      setLoading(true);

      const result = await callback(arg);
      if (result && typeof result === "object" && "error" in result) {
        throw new Error((result as any).error);
      }

      setLoading(false);
      setResult(result);
    } catch (e: any) {
      setError(e.toString());
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, depends);

  const LoadingElement = LoadingEl(loading, error);

  return { LoadingElement, loading, error, callback: theCallback, result };
}
