import { Grid, Link } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {createStyles, makeStyles} from "@mui/styles";
import {Theme} from "@mui/material/styles";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      maxWidth: 150,
      maxHeight: 50,
    },
    brandPadding: {
      padding: theme.spacing(2),
    },
    overflowHidden: {
      overflow: "hidden",
    },
    textOverflow: {
      display: "block",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxWidth: "100%",
    },
  })
);

export function HeaderLeft(props: { img: string; name: string; url: string }) {
  const styles = useStyle();

  return (
    <div className={styles.brandPadding}>
      <Grid container spacing={2} alignItems="center" wrap="nowrap">
        {props.img && (
          <Grid item>
            <img src={props.img} alt="logo" className={styles.logo} />
          </Grid>
        )}
        <Grid item xs className={styles.overflowHidden}>
          <Typography className={styles.textOverflow} variant="h5">
            {props.name || "Company Name"}
          </Typography>
          <Link className={styles.textOverflow} href={props.url}>
            {props.url || "https://company.com"}
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}
