import { Grid, Theme } from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import Typography from "@mui/material/Typography";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Card from "@mui/material/Card/Card";
import * as React from "react";
import green from "@mui/material/colors/green";
import { yellow } from "@mui/material/colors";
import WarningIcon from "@mui/icons-material/Warning";
import moment from "moment";
import { useTimeBasedRefresher } from "../TimeRefresher";
import { HeaderLeft } from "./HeaderLeft";
import { StatusPagePublicDetails } from "../../api/Types";
import {useWindowSize} from "./StatusGrid";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      height: theme.spacing(4),
    },
    status: {
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#fff",
    },
    statusSuccess: {
      backgroundColor: green["800"],
    },
    statusWarning: {
      backgroundColor: yellow["800"],
    },
    statusInner: {
      position: "relative",
      top: theme.spacing(0.5),
    },
  })
);

type DateString = string;

export function Header(props: {
  details: StatusPagePublicDetails;
  lastUpdated: DateString;
}) {
  useTimeBasedRefresher();

  return (
    <Card>
      <Grid container>
        <Grid item xs={7}>
          <HeaderLeft
            img={props.details.logoURL}
            name={props.details.companyName}
            url={props.details.companyURL}
          />
        </Grid>
        <Grid item xs={5}>
          <HeaderRight {...props} />
        </Grid>
      </Grid>
    </Card>
  );
}

function HeaderRight(props: {
    details: StatusPagePublicDetails;
    lastUpdated: DateString;
}) {
    const styles = useStyle();
    const seconds = secondsAgo(props.lastUpdated);
    const statuses = props.details.statuses || [];
    const success = statuses.filter((s) => s.current === false).length === 0;

    const {width} = useWindowSize();
    if(width < 800) {
        return (
            <div
                className={
                    styles.status +
                    " " +
                    (success ? styles.statusSuccess : styles.statusWarning)
                }
            >
                <Grid container justifyContent="center" className={styles.statusInner}>
                    <Grid item>
                        <Grid container spacing={2} alignItems="flex-start">
                            <Grid item>
                                {success ? (
                                    <CheckCircleIcon color="inherit" />
                                ) : (
                                    <WarningIcon color="inherit" />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return (
        <div
            className={
                styles.status +
                " " +
                (success ? styles.statusSuccess : styles.statusWarning)
            }
        >
            <Grid container justifyContent="center" className={styles.statusInner}>
                <Grid item>
                    <Grid container spacing={2} alignItems="flex-start">
                        <Grid item>
                            {success ? (
                                <CheckCircleIcon color="inherit" />
                            ) : (
                                <WarningIcon color="inherit" />
                            )}
                        </Grid>
                        <Grid item>
                            <Typography variant="h5" style={{ fontSize: 18 }}>
                                {success
                                    ? "Everything is operating normally"
                                    : "We’re having an incident"}
                            </Typography>
                            <Typography variant="caption">
                                Last checked {seconds}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
}

export function secondsAgo(date: string, includeAgo: boolean = false) {
    const ago = includeAgo ? " ago" : ""
  const dur = moment.duration(moment(date).diff(moment()));
  if(dur.asMinutes() > 1) {
      return round(dur.asMinutes(), 2) + "min" + ago
  }

  if(dur.asSeconds() > 1) {
      return round(dur.asSeconds(), 2) + "sec" + ago
  }

  return "just now";
}

function round(value: number, decimals: number) {
  return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);

}