import * as React from "react";
import { Card } from "../Card";
import {
  Link,
  Theme,
  Typography,
} from "@mui/material";
import {makeStyles, createStyles} from '@mui/styles';
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useTimeBasedRefresher } from "../TimeRefresher";
import grey from "@mui/material/colors/grey";
import { StatusPageMessage } from "../../api/Types";

const useStyle = makeStyles((theme: Theme) =>
  createStyles({
    lastMessage: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    message: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(1),
      borderBottom: "1px solid " + grey["300"],
    },
    messageText: {
      whiteSpace: "pre-wrap",
    },
  })
);

type DateString = string;

export function Messages(props: { messages: StatusPageMessage[] }) {
  const styles = useStyle();
  useTimeBasedRefresher();

  return (
    <Card slim>
      {props.messages.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          No recent messages
        </Typography>
      ) : (
        props.messages.map((m, index) => {
          return (
            <div
              key={index}
              className={
                index === props.messages.length - 1
                  ? styles.lastMessage
                  : styles.message
              }
            >
              <Grid container alignItems="flex-start">
                <Grid item xs>
                  <MessageText text={m.content} />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="textPrimary">
                    {formatTimestamp(m.timestamp)}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          );
        })
      )}
    </Card>
  );
}

export function formatTimestamp(when: DateString) {
  const timestamp = moment(when);
  const diff = moment().diff(timestamp);
  if (diff <= 60 * 1000) {
    return moment.duration(-diff).humanize(true);
  }

  if (moment().date() === timestamp.date()) {
    return timestamp.format("h:mma") + " (local)";
  }

  return timestamp.format("ddd h:mma") + " (local)";
}

export function MessageText(props: { text: string }) {
  const styles = useStyle();
  const textParts = formatText(props.text);

  return (
    <Typography
      variant="body1"
      color="textPrimary"
      className={styles.messageText}
    >
      {textParts}
    </Typography>
  );
}

export function formatText(text: string) {
  const words = text.split(" ");
  let textBuffer: string[] = [];
  const out: (string | JSX.Element)[] = [];

  const flushTextBuffer = function () {
    if (textBuffer.length > 0) {
      out.push(textBuffer.join(" ") + " ");
      textBuffer = [];
    }
  };

  words.map((w) => {
    if (w.match(/http[s]?:\/\//)) {
      flushTextBuffer();
      out.push(<Link href={w}>{w}</Link>);
      return null;
    }

    if (w.match(/.+@[^.]+\..+/)) {
      flushTextBuffer();
      out.push(<Link href={"mailto:" + w}>{w}</Link>);
      return null;
    }

    textBuffer.push(w);
    return null;
  });

  flushTextBuffer();
  return out;
}
