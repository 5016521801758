import * as React from "react";
import { grey } from "@mui/material/colors";
import Grid from "@mui/material/Grid";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: grey["300"],
      padding: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      fontSize: 13,
      color: grey["900"],
      "& a": {
        color: grey["900"],
      },
    },
  })
);

export function Footer(props: {}) {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <Grid container spacing={2}>
        <Grid item>
          Monitoring and diagnostic services provided by{" "}
          <a href="https://statuslist.app">Status List</a>
        </Grid>
        <Grid item>
          Read more on{" "}
          <a href="https://statuslist.app/uptime-monitoring/guides/">
            Our blog
          </a>
        </Grid>
        <Grid item>
          Questions?{" "}
          <a href="mailto:hello@statuslist.app">hello@statuslist.app</a>
        </Grid>
      </Grid>
    </div>
  );
}
